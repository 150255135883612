import React, { useState, useEffect, useCallback, useRef } from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { useData, useLoading } from '@data-provider/react';
import { motion } from 'framer-motion';
import { usePaginatedQuery, queryCache, useInfiniteQuery, QueryResult } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import LayoutContent from '@components/Layout';
import Header from '@components/Header';
import SEO from '@components/Seo';
import MiniFooter from '@components/Footer/MiniFooter';
import CookieBanner from '@components/Cookies/CookieBanner';

// import { getBusinessPage } from '@services/business.service';
import BusinessAPI from '@services/api-query.service';
import { IBusiness, IAPIResponse, IPagination } from '@models';

import BusinessListCardComponent from '@components/business/BusinessListCardComponent';
import PaginationListComponent from '@components/pagination/PaginationListComponent';
import { IPageParams } from '@models/pagination.model';
import { useGetBusinessPage, useGetBusinessInfinite, getBusinessPage, useGetBusinessMap } from '@services/business.service';
import { CircularProgress } from '@material-ui/core';
import imgHero from "@assets/images/app/fill.svg";
import styled from 'styled-components';

import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import InfoMap from '@components/map/InfoMap';
import { MapServiceProviders } from '@components/map/map.model';
import { IMap, IMapFeature } from '@models/map.model';
import InfoMarker from '@components/map/InfoMarker';
import BusinessListMapComponent from '@components/business/BusinessListMapComponent';

const SectionContainer= tw.section`p-4 pt-4 md:pt-32 block flex justify-center flex-wrap flex-row items-stretch w-full`;
const ArticlesContainer= tw.div`sm:w-full max-w-6xl mx-auto pt-20 sm:pt-0 md:pt-10 z-0`;
const CardContainerList = tw(motion.div)`mt-16 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-3`;
const CardContainer = tw.div`mt-2 md:mt-10 w-1/2 md:w-1/3 lg:w-1/4 sm:pr-2 md:pr-6 lg:pr-12`;
const PageContainer = tw.div`mt-2 flex flex-row items-end justify-end text-xl font-bold text-primarydark`
const MoreIcon = tw(FaChevronDown)`w-12 h-12 text-primary-900`;
const Container = tw.div`mt-16 flex w-full`;
const ContainerList = tw.div`hidden sm:w-1/2 sm:block mx-3 space-y-3`;
const ContainerMap = tw.div`w-full sm:w-1/2`;
const InputSearch =tw.input`w-full mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none`;
const BtnPrevious=tw.button`border border-teal-500 text-teal-500 block rounded-full font-bold py-4 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-white`;
const BtnNext=tw.button`border border-teal-500 text-teal-500 block rounded-full font-bold py-4 px-6 ml-2 flex items-center`;
const BusinessListContainer= tw.div`flex pr-0 pt-0`;
const BusinessTitleContainer = tw.div`flex-grow flex space-x-2 items-end `;
const BusinessListTitle = tw.h3`text-thirdary-700 text-3xl font-bold`;
const BusinessListCounter = tw.span`text-thirdary-200 text-2xl font-semibold`;

const SectionContainerStyled = styled(SectionContainer)`
        // padding-top: 100px;
        background: url(${imgHero}) no-repeat;
        background-size: 100% 150%;
        background-position: 45vw;
        // padding-left: 100px;


        .markerClusterSmall {
            border-radius: 30px;
            //font-size: 0.8vw;
            color: #fff;
            font-weight: 900;
            background-color: #1c676d;
            padding: 1em;
            width: 50px !important;
            height: 50px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            offset: -50px;
            top: -25px;
            left: -25px;
            box-shadow: 0 0 0 5px rgba(28, 100, 109, 0.41);
         }
         .markerClusterMedium {
            border-radius: 40px;
            //font-size: 1vw;
            color: #fff;
            font-weight: 900;
            font-family: 'Barlow';
            text-transform: uppercase;
            background-color: #1c676d;
            padding: 1em;
            width: 50px !important;
            height: 50px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            offset: -50px;
            top: -25px;
            left: -25px;
            //border-width: 5px;
            //border-color: rgba(28, 100, 109, 0.25);
            box-shadow: 0 0 0 5px rgba(28, 100, 109, 0.41);
         }
         .markerClusterLarge {
            border-radius: 70px;
            //font-size: 0.6vw;
            color: #fff;
            font-weight: 900;
            font-family: 'Barlow SemiBold';
            text-transform: uppercase;
            background-color: #1c676d;
            padding: 1em;
            width: 50px !important;
            height: 50px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            offset: -50px;
            top: -25px;
            left: -25px;
            border-width: 5px;
            border-color: rgba(28, 100, 109, 0.25);   
            box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.51);
         }
         .markerClusterLargeXL {
            border-radius: 70px;
            //font-size: 0.6vw;
            color: #fff;
            font-weight: 900;
            font-family: 'Barlow SemiBold';
            text-transform: uppercase;
            background-color: #1c676d;
            padding: 1em;
            width: 50px !important;
            height: 50px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            offset: -50px;
            top: -25px;
            left: -25px;
            border-width: 5px;
            border-color: rgba(28, 100, 109, 0.25);    
            box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.51);
         }
         .markerClusterLabel {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Barlow ExtraBold';
            text-transform: uppercase;   
            font-size: 14px;        
         }

        `;  
        
const BusinessMap : React.FC<any> = props => { // ({location, pageContext})

    const { status, data, error, isFetching }:QueryResult<IAPIResponse<IMap<IBusiness>>> = useGetBusinessMap();

    //let data: IAPIResponse<IMap<IBusiness>>;

    const size: number = 5;
    const [pageNumber, setPageNumber] = useState(0);
    //let businessPage:IAPIResponse<IPagination<IBusiness>>; // = useGetBusinessPage(pageNumber);
    const businessPage:IAPIResponse<IPagination<IBusiness>> = useGetBusinessPage(pageNumber, size);



    const [businessList, setBusinessList] = useState<Array<IBusiness>>(new Array());

    const debug = false;
    // -- map
    const markerRef = useRef();

    let LOCATION = {
        lat: process.env.LATITUDE,
        lng: process.env.LONGITUDE        
        // lat: 40.416775,
        // lng: -3.70379,  
//        lat: business.address?.latitude,
//        lng: business.address?.longitude
      };
    
    const DEFAULT_ZOOM = 13; //process.env.DEFAULT_ZOOM; //14;
    const ZOOM = process.env.ZOOM; // 13;    





    /**
     * mapEffect
     * @description Fires a callback once the page renders
     * @example Here this is and example of being used to zoom in and set a popup on load
     */
    async function mapEffect({ leafletElement } = {}) {

        
            
        
        // console.log('business Detail mapEffect -- ', leafletElement);
        if ( !leafletElement) return;

        const popup = L.popup({
        maxWidth: 800,
        });

        const location = LOCATION;


        const { current = {} } = markerRef || {};
        const { leafletElement: marker } = current;
    }

    const createClusterCustomIcon = (cluster) => {
        const count = cluster.getChildCount();
        let size = 'LargeXL';
      
        if (count < 10) {
          size = 'Small';
        }
        else if (count >= 10 && count < 100) {
          size = 'Medium';
        }
        else if (count >= 100 && count < 500) {
          size = 'Large';
        }
        const options = {
          cluster: `markerCluster${size}`,
        };
      
        return L.divIcon({
          html:
            `<div>
              <span class="markerClusterLabel">${count}</span>
            </div>`,
          className: `${options.cluster}`,
        });
    };

    const showPage = () => {
        return (
            <ContainerList>
            { false &&
            < div className="flex flex-col">
                <InputSearch type="name" name="name" id="name" placeholder="Busca establecimiento"/>
            </div>
            }


            <BusinessListContainer>

                <BusinessTitleContainer>
                    <BusinessListTitle>Establecimientos</BusinessListTitle>
                    <BusinessListCounter>{businessPage?.data?.totalElements}</BusinessListCounter>
                </BusinessTitleContainer>
                    {
                    businessPage?.data?.first === false &&
                    <BtnPrevious onClick={ () => setPageNumber(pageNumber-1)}
                                disabled={ pageNumber === 0}>
                        <FaChevronLeft/>
                    </BtnPrevious>
                    }
                    {
                    businessPage?.data?.last === false &&                        
                    <BtnNext onClick={() => setPageNumber(pageNumber+1)}>
                        <FaChevronRight/>
                    </BtnNext>
                    }
            </BusinessListContainer>



            <React.Fragment>
            {
                businessPage?.data?.content.map((business: IBusiness, index: number) => (
                <BusinessListMapComponent business={business} key={business.token}></BusinessListMapComponent>
            ))}
            </React.Fragment>
        </ContainerList>
        );
    }


    const showMap = () => {

        const geoJson:IMap<IBusiness> = data;
        const CENTER = [LOCATION.lat, LOCATION.lng];
    
        const mapSettings = {
            center: CENTER,
            defaultBaseMap: MapServiceProviders.MAPBOX,
            zoom: DEFAULT_ZOOM,
            zoomControl: false,
            minZoom: 12,
            maxZoom: 18,
            mapEffect
        };
    
        // console.log('showingMap ---- rendering ', geoJson);

        // -- marcadores 
        return (
                <ContainerMap>
                <InfoMap {...mapSettings}>
                    <MarkerClusterGroup
                    iconCreateFunction={createClusterCustomIcon}
                    spiderLegPolylineOptions={{
                        weight: 0,
                        opacity: 0,
                    }}
                    >
                        {
                        geoJson?.features?.map((geojson: IMapFeature<IBusiness>, index: number) => (
                            <InfoMarker business={geojson.properties} small={true} key={geojson.properties.token}/>
                        ))}
                    </MarkerClusterGroup>
                </InfoMap>
                </ContainerMap>
        );
    }


    return (
        <LayoutContent>
            <Header fullWidth={true}/>
            <SEO
                lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
            />       
            <SectionContainerStyled id="business-map">
            <>
            {(data === undefined || businessPage === undefined) && 
            <CircularProgress color="primary" />
            }
        
            <Container>
            {businessPage && 
                 showPage()       
            }                   
            {data && 
                 showMap()       
            }             
            </Container>      
            </>
            </SectionContainerStyled>    
            

            <MiniFooter />
            <CookieBanner/>
            {debug && 
                <ReactQueryDevtools initialIsOpen />
            }
        </LayoutContent>
    );
}

export default BusinessMap;
